import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import HTMLContent from "../components/Content";
import CustomLink from "../components/CustomLink";
import ImageGallery from "react-image-gallery";
import CustomImage from "../components/Image";

import "../styles/rooms.scss";

export const RoomsPageTemplate = props => {
  const { page } = props;
  if (!page.frontmatter.rooms) return null;

  const renderCustomItem = item => {
    return (
      <CustomImage
        gatsbyImage={item.gatsbyImage.childImageSharp}
        src={item.gatsbyImage.childImageSharp}
        otherProps={{ objectFit: "contain" }}
      />
    );
  };

  return (
    <article className="rooms">
      <div className="rooms-container container">
        <section className="rooms-header">
          <div className="rooms-titleWrapper">
            <h1 className="rooms-title">{page.frontmatter.title}</h1>
          </div>
          <div className="rooms-imageWrapper">
            <CustomImage
              gatsbyImage={page.frontmatter.mainImage.image.childImageSharp}
              alt={page.frontmatter.mainImage.imageAlt}
              src={page.frontmatter.mainImage.image}
            />
          </div>
        </section>
        <section className="section">
          {page.bodyIsMarkdown ? (
            <ReactMarkdown className="rooms-description" source={page.html} />
          ) : (
            <HTMLContent className="rooms-description" content={page.html} />
          )}
        </section>
        <section className="rooms-list">
          {page.frontmatter.rooms.map((room, idx) => {
            const images = room.gallery.map(i => {
              return {
                original: i.image.id,
                thumbnail: i.image.childImageSharp.fluid.src,
                alt: i.alt,
                gatsbyImage: i.image
              };
            });

            return (
              <div className="room" key={idx}>
                <h2>{room.title}</h2>
                <div>
                  {page.bodyIsMarkdown ? (
                    <ReactMarkdown
                      className="room-description"
                      source={room.description}
                    />
                  ) : (
                    <HTMLContent
                      className="room-description"
                      content={room.description}
                    />
                  )}
                </div>
                <ImageGallery
                  items={images}
                  showThumbnails={true}
                  showPlayButton={false}
                  showNav={true}
                  showFullscreenButton={true}
                  autoPlay={false}
                  renderItem={renderCustomItem}
                  renderThumbInner={renderCustomItem}
                />

                <div className="room-footer">
                  <div className="room-price">
                    <b>Preis pro Nacht:</b> {`${room.price} €`}
                  </div>
                  <CustomLink
                    linkType="internal"
                    linkURL="/buchen#reservation-form"
                  >
                    Jetzt buchen
                  </CustomLink>
                </div>
              </div>
            );
          })}
        </section>
      </div>
    </article>
  );
};

const RoomsPage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle }
    }
  } = page;

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <RoomsPageTemplate page={{ ...page, bodyIsMarkdown: false }} />
    </Layout>
  );
};

RoomsPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default RoomsPage;

export const roomsPageQuery = graphql`
  query RoomsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        mainImage {
          image {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          imageAlt
        }
        rooms {
          gallery {
            image {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            imageAlt
            name
          }
          title
          description
          price
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
